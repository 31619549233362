import ResponsiveBox from 'components/layout/ResponsiveBox';
import styled from 'styled-components';
import { desktop, tablet } from 'utils/media';
import theme from 'utils/theme';

export const StyledResponsiveBox = styled(ResponsiveBox)({
  backgroundColor: theme.colors.offWhite,
  zIndex: 0,
  borderRadius: 24,
  [desktop]: {
    borderRadius: 32,
  },
});

export const Label = styled.div({
  fontFamily: theme.fonts.heading,
  marginTop: 16,
  fontWeight: theme.weights.headingBold,
  fontSize: 14,
  [tablet]: {
    fontSize: 24,
  },
});

export const MaskedIconWrapper = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  color: theme.colors.white,
  backgroundColor: theme.colors.iconUnderlay,
});

export const StyledLink = styled.a({
  color: theme.colors.black,
  textAlign: 'center',
  '&:hover, &:focus': {
    color: theme.colors.purple,
  },
});

export const TitleRow = styled.div({
  padding: '0 20px',
  color: theme.colors.purple,
  marginBottom: 20,
});

import React from 'react';
import Section from 'components/layout/Section';
import { Heading2 } from 'components/text';
import { TitleRow, CuratorLinkA } from './styles';
import { SocialBlockProps } from './types';

export default function SocialBlock({ divId, title, body }: SocialBlockProps) {
  return (
    <Section>
      {title || body ? (
        <TitleRow>
          {title ? <Heading2 inline>{title}</Heading2> : null}
          {body ? <p>{body}</p> : null}
        </TitleRow>
      ) : null}
      <div id={divId}>
        <CuratorLinkA
          href="https://curator.io"
          target="_blank"
          className="crt-logo"
        >
          Powered by Curator.io
        </CuratorLinkA>
      </div>
    </Section>
  );
}

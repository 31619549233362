import styled, { CSSProperties } from 'styled-components';
import { tablet, desktop, mobile } from 'utils/media';
import theme from 'utils/theme';
import { CarouselItemProps } from './types';

const scrollbarHeight = 2;
const scrollbarPadding = 20;
const scrollbarTopPadding = 30;

const scrollbarStyles = (inline: boolean) => ({
  '::-webkit-scrollbar': {
    height: scrollbarTopPadding + scrollbarHeight,
    backgroundColor: 'rgba(255,255,255,0)',
  },
  '::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb': {
    border: `${scrollbarPadding}px solid rgba(255,255,255,0)`,
    borderTopWidth: scrollbarTopPadding,
    borderBottomWidth: 0,
    backgroundClip: 'padding-box',
    ...(inline
      ? { [tablet]: { borderLeftWidth: 0, borderRightWidth: 0 } }
      : {}),
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: theme.colors.scrollbarBackground,
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.colors.darkGreen,
  },
});

interface CarouselRowInnerProps {
  inline?: boolean;
  snap?: boolean;
}

export const CarouselRowInner = styled.div<CarouselRowInnerProps>(
  ({ inline = false, snap = true }) => ({
    display: 'flex',
    flex: 1,
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: '0',
    overscrollBehaviorX: 'contain',
    scrollSnapType: snap ? 'x mandatory' : undefined,
    [desktop]: {
      padding: 0,
    },
    ...scrollbarStyles(inline),
  }),
);

export const CarouselRowContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

interface ArrowButtonProps {
  arrowLayout: 'overlay' | 'inset';
}

export const ArrowButton = styled.button<ArrowButtonProps>`
  ${mobile} {
    display: none;
  }

  position: ${(props) =>
    props.arrowLayout === 'overlay' ? 'absolute' : 'static'};
  top: 50%;
  appearance: none;
  border: none;
  background: rgba(255, 255, 255, 0.5);
  z-index: ${theme.zIndexes.mid};
  cursor: pointer;
  color: ${theme.colors.darkGreen};
  width: 37px;
  height: 37px;
  line-height: 0;
  font-size: 0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.disabled
      ? `
  visibility: hidden;
`
      : ``}
  ${(props) =>
    props.arrowLayout === 'overlay'
      ? `
transform: translateY(-50%);`
      : `margin-top: 46px;`}

svg {
    width: 29px;
    height: 29px;
  }

  &:first-child {
    left: ${(props) => (props.arrowLayout === 'overlay' ? '20px' : '0')};

    svg {
      transform: rotate(90deg);
    }
  }

  &:last-child {
    right: ${(props) => (props.arrowLayout === 'overlay' ? '20px' : '0')};

    svg {
      transform: rotate(-90deg);
    }
  }

  &:hover {
    background: ${theme.colors.white};
  }

  &:focus-visible {
    background: ${theme.colors.white};
    outline: none;
    box-shadow: 0 0 0 2px ${theme.colors.darkGreen};
  }
`;

const sizeStyles = {
  small: {
    minWidth: 132,
    maxWidth: 132,
  },
  normal: {
    minWidth: 134,
    maxWidth: 264,
  },
  large: {
    minWidth: 150,
    width: 150,
    flexGrow: 0,
    [tablet]: {
      minWidth: 290,
      width: 290,
    },
  },
  xlarge: {
    width: 240,
    minWidth: 240,
    [tablet]: {
      width: 290,
      minWidth: 290,
    },
  },
  xxlarge: {
    [mobile]: {
      width: 240,
      minWidth: 240,
    },
    width: 400,
    minWidth: 400,
  },
  full: {
    boxSizing: 'border-box',
    minWidth: '100%',
    width: '100%',
    flexShrink: 0,
    padding: '0 20px',
    [tablet]: {
      padding: 0,
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
  },
} as { [key: string]: CSSProperties };

export const CarouselItem = styled.div<CarouselItemProps>(
  ({ size = 'normal', inline }) => ({
    boxSizing: 'content-box',
    flexShrink: 0,
    padding: '0 10px 35px 10px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    scrollSnapAlign: 'center',
    [desktop]: {
      padding: '0 20px',
    },
    ...(inline
      ? {
          '&:first-child': {
            paddingLeft: 20,
          },
          '&:last-child': {
            paddingRight: 20,
          },
          [tablet]: {
            '&:first-child': {
              paddingLeft: 0,
            },
            '&:last-child': {
              paddingRight: 0,
            },
          },
        }
      : {
          '&:first-child': {
            paddingLeft: 20,
          },
          '&:last-child': {
            paddingRight: 20,
          },
        }),
    ...sizeStyles[size],
  }),
);

export const ThumbnailsGrid = styled.div`
  margin-top: 30px;
  grid-auto-flow: row dense;
  column-gap: 15px;
  row-gap: 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${tablet} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  ${desktop} {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const ThumbnailLink = styled.button`
  appearance: none;
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

export const ThumbnailContent = styled.div`
  ${mobile} {
    display: none;
  }
`;

interface ThumbnailDotProps {
  active: boolean;
}

export const ThumbnailDot = styled.div<ThumbnailDotProps>`
  display: none;

  ${mobile} {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: ${(props) =>
      props.active ? theme.colors.darkGreen : theme.colors.scrollbarBackground};
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    border-radius: 100%;
  }
`;

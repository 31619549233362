// import BodyTextContainer from 'components/layout/BodyTextContainer';
import Container from 'components/layout/Container';
import React from 'react';
import { Heading3 } from 'components/text';
import { SimpleTitleAndTextProps } from './types';
import { TextContainer, StyleInnerContainer } from './style';
import { PortableText } from 'utils/sanity';

export default function SimpleTitleAndText({
  title,
  body,
}: SimpleTitleAndTextProps) {
  return (
    <Container>
      <StyleInnerContainer>
        {title && <Heading3>{title}</Heading3>}
        {body && (
          <TextContainer>
            <PortableText blocks={body} />
          </TextContainer>
        )}
      </StyleInnerContainer>
    </Container>
  );
}

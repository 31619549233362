import BodyTextContainer from 'components/layout/BodyTextContainer';
import Container from 'components/layout/Container';
import React from 'react';
import { PortableText } from 'utils/sanity';
import { TextProps } from './types';

export default function Text({ body }: TextProps) {
  return (
    <Container>
      <BodyTextContainer>
        <PortableText blocks={body} />
      </BodyTextContainer>
    </Container>
  );
}

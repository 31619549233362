import { CarouselRow, CarouselItem } from 'components/layout/Carousel';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import { Heading2 } from 'components/text';
import SiteLink from 'components/Link';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { mobile } from 'utils/media';
import Image from 'components/Image';
import formatPrice from 'utils/formatPrice';
import { getProductUrl } from 'utils/commercetools/product';
import {
  TitleRow,
  TabsRow,
  TabLink,
  StyledResponsiveBox,
  Label,
  Price,
} from './styles';

export interface ProductCarouselTab {
  _key: string;
  title: ReactNode;
  products?: Sproutl.ProductListing[];
}

export interface ProductCarouselProps {
  tabs?: ProductCarouselTab[];
}

interface StyledHeadingProps {
  hasTabs: boolean;
}

const StyledHeading = styled(Heading2)<StyledHeadingProps>((props) =>
  props.hasTabs
    ? {
        [mobile]: {
          display: 'none',
        },
      }
    : {},
);

export default function ProductCarousel({ tabs = [] }: ProductCarouselProps) {
  const [selectedTab, selectTab] = useState(0);

  if (!tabs.length) {
    return null;
  }

  return (
    <Section bg="normal">
      <Container noPadding>
        <TitleRow>
          <StyledHeading hasTabs={tabs.length > 1} inline>
            {tabs[selectedTab].title}
          </StyledHeading>
          {tabs.length > 1 ? (
            <TabsRow>
              {tabs.map((tab, index) => (
                <TabLink
                  key={index}
                  onClick={() => selectTab(index)}
                  active={index === selectedTab}
                >
                  {tab.title}
                </TabLink>
              ))}
            </TabsRow>
          ) : null}
        </TitleRow>
        <CarouselRow>
          {tabs[selectedTab].products?.map((product) => {
            if (!product || !product.sku) {
              return null;
            }

            return (
              <CarouselItem
                size="large"
                key={product.sku}
                data-testid="productCarouselItem"
              >
                <SiteLink href={getProductUrl(product)} title={product.name}>
                  <StyledResponsiveBox aspectRatio={1}>
                    {product.photo ? (
                      <Image
                        loader="cloudinary"
                        src={product.photo}
                        aspectRatio={1}
                        sizes={`${mobile} 150px, 290px`}
                        alt={product.name}
                      />
                    ) : null}
                  </StyledResponsiveBox>
                  <Label>
                    <div>{product.name}</div>
                    {product.price ? (
                      <Price>{formatPrice(product.price)}</Price>
                    ) : null}
                  </Label>
                </SiteLink>
              </CarouselItem>
            );
          })}
        </CarouselRow>
      </Container>
    </Section>
  );
}

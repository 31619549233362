import { Heading2 } from 'components/text';
import { mobile, tablet, desktop } from 'utils/media';
import Button from 'components/Button';
import Image from 'components/Image';
import React from 'react';
import BackgroundVideo from 'components/BackgroundVideo';
import Link from 'components/Link';
import { BillboardProps } from './types';
import {
  TwoColWrapper,
  Banner,
  TextSection,
  ButtonWrapper,
  LabelWrapper,
  StyledHeading4,
  StyledMaskedIcon,
  OverlayResponsiveBox,
} from './styles';

function Billboard({
  colorScheme = {
    background: 'pineGreen',
    text: 'coleusGreen',
  },
  title,
  body,
  label,
  cta,
  banner,
  bannerAlignment,
  hasPadding,
  darkenOverlay,
  video,
}: BillboardProps) {
  return bannerAlignment === 'leftOverlay' ? (
    <OverlayResponsiveBox
      hasPadding={hasPadding}
      aspectRatio={{ [mobile]: 4 / 3, [tablet]: 1 / 3 }}
      textColor={colorScheme?.text}
    >
      <Image
        src={banner}
        aspectRatio={{ [mobile]: 4 / 3, [tablet]: 1 / 3 }}
        sizes={`${mobile} 100vw, 50vw`}
        alt=""
      />
      <TextSection alignment={bannerAlignment} darkenOverlay={darkenOverlay}>
        <div>
          {label ? (
            <LabelWrapper>
              <StyledHeading4 as="p">
                {label.icon ? <StyledMaskedIcon asset={label.icon} /> : null}
                {label.copy}
              </StyledHeading4>
            </LabelWrapper>
          ) : null}
          {title ? <Heading2 inline>{title}</Heading2> : null}
          {body ? <p>{body}</p> : null}
          {cta && cta.title && cta.url ? (
            <ButtonWrapper>
              <Button
                as="span"
                variant="secondary"
                colorScheme={colorScheme?.text}
              >
                {cta.title}
              </Button>
            </ButtonWrapper>
          ) : null}
        </div>
      </TextSection>
    </OverlayResponsiveBox>
  ) : (
    <TwoColWrapper
      hasPadding={hasPadding}
      alignment={bannerAlignment}
      backgroundColor={colorScheme?.background}
      textColor={colorScheme?.text}
    >
      {banner || video ? (
        <Banner alignment={bannerAlignment}>
          {video ? (
            <BackgroundVideo
              video={video}
              breakpoints={{
                [mobile]: { width: 750, height: 488 },
                [tablet]: { width: 1024, height: 690 },
                [desktop]: { width: 1920, height: 1288 },
              }}
            />
          ) : banner ? (
            <Image
              src={banner}
              aspectRatio={{ [mobile]: 0.64, [tablet]: 2 / 3 }}
              sizes={`${mobile} 100vw, 50vw`}
              alt=""
            />
          ) : null}
        </Banner>
      ) : null}
      <TextSection alignment={bannerAlignment} darkenOverlay={darkenOverlay}>
        <div>
          {label ? (
            <LabelWrapper>
              <StyledHeading4 as="p">
                {label.icon ? <StyledMaskedIcon asset={label.icon} /> : null}
                {label.copy}
              </StyledHeading4>
            </LabelWrapper>
          ) : null}
          {title ? <Heading2 inline>{title}</Heading2> : null}
          {body ? <p>{body}</p> : null}
          {cta && cta.title && cta.url ? (
            <ButtonWrapper>
              <Button
                as="span"
                variant="secondary"
                colorScheme={colorScheme?.text}
              >
                {cta.title}
              </Button>
            </ButtonWrapper>
          ) : null}
        </div>
      </TextSection>
    </TwoColWrapper>
  );
}

export default function BillboardWrapper(billboardProps: BillboardProps) {
  const { cta } = billboardProps;

  return cta && cta.title && cta.url ? (
    <Link href={cta.url}>
      <Billboard {...billboardProps} />
    </Link>
  ) : (
    <Billboard {...billboardProps} />
  );
}

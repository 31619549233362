import styled from 'styled-components';
import { Heading2 } from 'components/text';
import { desktop, tablet } from 'utils/media';
import theme from 'utils/theme';

export const TitleRow = styled.div`
  padding: 0 20px;
  margin-bottom: 20px;

  ${Heading2} {
    color: ${theme.colors.purple};
  }

  p {
    margin: 0 0 30px;
  }

  ${tablet} {
    padding: 0 40px;
  }

  ${desktop} {
    padding: 0 80px;

    p {
      margin: 0 0 45px;
    }
  }
`;

export const CuratorLinkA = styled.a`
  color: transparent;
  font-size: 0;
  line-height: 0;
`;
